import React from 'react'
import './Home.scss'

const Home = () => {
    return (
        <div className="home">
            <div className="maintenance">
                <p>
                    Les Bâtisseuses construisent le site...
                    <br />
                    Nous vous invitons à suivre l'avancement des travaux !
                </p>
            </div>
        </div>
    )
}

export default Home
