import React from 'react'
import './Footer.scss'

import Batisse from '../../asset/batisse.png'

const Footer = () => {
    return (
        <div className="footer">
            <img
                className="footer__banner"
                alt="batisse en construction"
                src={Batisse}
            ></img>
        </div>
    )
}

export default Footer
