import React from 'react'
import './Header.scss'

import Batisse from '../../asset/batisse.png'

const Header = () => {
    return (
        <div className="header">
            <img
                className="header__banner"
                alt="plan de batisse"
                src={Batisse}
            ></img>
        </div>
    )
}

export default Header
